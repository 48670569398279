(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/best-bet-rows/assets/javascripts/single-row.js') >= 0) return;  svs.modules.push('/components/tipsen/best-bet-rows/assets/javascripts/single-row.js');
"use strict";


const {
  ReactButtonBet: ButtonBet
} = svs.ui;
const {
  signIndexToOutcome
} = svs.components.sport.tipsenShared;
const {
  useBranding
} = svs.components.tipsen.hooks;
const SingleRow = React.memo(_ref => {
  let {
    row,
    topic,
    drawResultOutcomes,
    eventTypeId,
    className
  } = _ref;
  const [productBranding] = useBranding();
  return React.createElement("div", {
    className: ['pg_best_bet_rows__row'].concat(className || []).join(' ')
  }, React.createElement("div", {
    className: "pg_best_bet_rows__row_topic"
  }, topic), React.createElement("div", {
    className: "pg_best_bet_rows__row_outcomes"
  }, row.map((outcome, index) => {
    var _drawResultOutcomes$i;
    return React.createElement(ButtonBet, {
      branding: productBranding,
      className: "pg_best_bet_rows__row_outcome"
      ,
      key: "".concat(outcome).concat(index),
      label: signIndexToOutcome[eventTypeId][outcome[0]],
      selected: outcome[0] === (drawResultOutcomes === null || drawResultOutcomes === void 0 || (_drawResultOutcomes$i = drawResultOutcomes[index]) === null || _drawResultOutcomes$i === void 0 ? void 0 : _drawResultOutcomes$i[0]),
      size: "100"
    });
  })));
}, (prevProps, nextProps) => prevProps.row.join('') === nextProps.row.join('') && prevProps.drawResultOutcomes.join('') === nextProps.drawResultOutcomes.join('') && prevProps.topic === nextProps.topic && prevProps.className === nextProps.className);
setGlobal('svs.tipsen.components.bestBetRows.SingleRow', SingleRow);

 })(window);