(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/best-bet-rows/assets/javascripts/use-single-rows.js') >= 0) return;  svs.modules.push('/components/tipsen/best-bet-rows/assets/javascripts/use-single-rows.js');
"use strict";


const {
  useMemo
} = React;
const {
  helpers
} = svs.components.tipsen.store;
const {
  useGetPlayerWagerQuery
} = svs.components.tipsen.store.tipsenApi;
const {
  getDefinitionByProduct
} = svs.components.tipsen.engine.utils;
const defaultReturn = [];
const useSingleRows = function (_ref) {
  var _productBet$gameBoard, _productBet$gameBoard2;
  let {
    wagerId
  } = _ref;
  let {
    skip = false
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const wager = useGetPlayerWagerQuery({
    wagerId
  }, {
    skip: !wagerId
  });
  const {
    productBet
  } = wager.data || {};
  const {
    boardData,
    systemType,
    systemNum
  } = (_productBet$gameBoard = productBet === null || productBet === void 0 || (_productBet$gameBoard2 = productBet.gameBoard) === null || _productBet$gameBoard2 === void 0 ? void 0 : _productBet$gameBoard2.boards) !== null && _productBet$gameBoard !== void 0 ? _productBet$gameBoard : {};
  const {
    productId
  } = productBet;
  const eventTypeId = productId ? getDefinitionByProduct(productId).outcomes.eventTypeId : 0;
  const srow = useMemo(() => {
    if (skip || !boardData) {
      return defaultReturn;
    }
    return helpers.getExpandedRows(boardData, systemType, systemNum, {
      eventTypeId
    });
  }, [boardData, systemType, systemNum, eventTypeId, skip]);
  return srow;
};
setGlobal('svs.tipsen.components.bestBetRows.useSingleRows', useSingleRows);

 })(window);