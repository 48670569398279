(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/best-bet-rows/assets/javascripts/best-bet-rows-context.js') >= 0) return;  svs.modules.push('/components/tipsen/best-bet-rows/assets/javascripts/best-bet-rows-context.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  useContext,
  useCallback,
  useEffect,
  useMemo,
  useRef
} = React;
const {
  useDrawResultOutcomes,
  BestBetRow
} = svs.tipsen.components.bestBetRows;
const {
  getLogger
} = svs.core.log;
const {
  useGetPlayerWagerQuery
} = svs.components.tipsen.store.tipsenApi;
const logger = getLogger('tipsen:best-bet-rows');
const BestBetRowsContext = React.createContext();
const useBestBetRows = () => {
  const context = useContext(BestBetRowsContext);
  if (context === undefined) {
    throw new Error('useBestBetRows must be used within a BestBetRowsContextProvider');
  }
  return context;
};
const defaultReturn = Object.freeze({
  bestBetRows: Object.freeze([]),
  winDivisions: Object.freeze([]),
  winDivisionsRowCount: Object.freeze([]),
  drawResultOutcomes: Object.freeze([]),
  productId: 0,
  numberOfRows: 0
});
const BestBetRowsContextProvider = _ref => {
  var _wager$data;
  let {
    wagerId,
    count = 60,
    children,
    skip = false
  } = _ref;
  const wager = useGetPlayerWagerQuery({
    wagerId
  }, {
    skip: skip || !wagerId
  });
  const {
    productBet
  } = (_wager$data = wager === null || wager === void 0 ? void 0 : wager.data) !== null && _wager$data !== void 0 ? _wager$data : {};
  const {
    productId
  } = productBet !== null && productBet !== void 0 ? productBet : {};
  const bestBetRows = useMemo(() => {
    if (!productBet) {
      return false;
    }
    return new BestBetRow(productBet, {
      maxCapRowCount: count
    });
  }, [productBet, count]);
  const [drawResultOutcomes, winDivisions, multiForecastCorrectEventNumberIndex] = useDrawResultOutcomes({
    wagerId
  });
  const previousCacheKey = useRef(drawResultOutcomes.join(''));
  const bestBetRowCache = useRef({});
  const key = drawResultOutcomes.join('');
  useEffect(() => {
    if (key && !previousCacheKey.current) {
      previousCacheKey.current = key;
    }
  }, [key]);
  const getBestBetRow = useCallback(function (outcomeIndex) {
    let isCorrectResultIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    if (!bestBetRows) {
      return defaultReturn;
    }
    const newDrawResults = drawResultOutcomes.slice();
    let cacheKey = newDrawResults.join('');
    if (outcomeIndex !== undefined) {
      newDrawResults[multiForecastCorrectEventNumberIndex] = outcomeIndex;
      cacheKey = newDrawResults.join('');
    }
    if (!bestBetRowCache.current[cacheKey]) {
      let bestBetRowValue = bestBetRows.calculate(newDrawResults, winDivisions);
      if (!isCorrectResultIndex) {
        bestBetRowValue = {
          winDivisionsRowCount: bestBetRowValue.winDivisionsRowCount
        };
      }
      bestBetRowCache.current[cacheKey] = bestBetRowValue;
    }
    if (outcomeIndex === undefined && Object.keys(bestBetRowCache.current).length > 1 && previousCacheKey.current !== cacheKey) {
      logger.debug("previousCacheKey: ".concat(previousCacheKey.current, ", newCacheKey: ").concat(cacheKey));
      delete bestBetRowCache.current[previousCacheKey.current];
      previousCacheKey.current = cacheKey;
    }
    logger.debug("Return bestBetRow for cacheKey: ".concat(cacheKey), bestBetRowCache.current[cacheKey]);
    return _objectSpread(_objectSpread({}, bestBetRowCache.current[cacheKey] || defaultReturn), {}, {
      drawResultOutcomes: Object.freeze(newDrawResults),
      winDivisions,
      productId,
      numberOfRows: bestBetRows.totalRowCount
    });
  }, [drawResultOutcomes, multiForecastCorrectEventNumberIndex, bestBetRows, winDivisions, productId]);
  return React.createElement(BestBetRowsContext.Provider, {
    value: getBestBetRow
  }, children);
};
setGlobal('svs.tipsen.components.bestBetRows', {
  useBestBetRows,
  BestBetRowsContextProvider,
  BestBetRowsContext
});

 })(window);