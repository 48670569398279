(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/best-bet-rows/assets/javascripts/best-bet-rows.js') >= 0) return;  svs.modules.push('/components/tipsen/best-bet-rows/assets/javascripts/best-bet-rows.js');
"use strict";


const {
  useMemo
} = React;
const {
  useBestBetRows,
  SingleRowsPresentation,
  SingleRow
} = svs.tipsen.components.bestBetRows;
const {
  getDefinitionByProduct
} = svs.components.tipsen.engine.utils;
const {
  getLogger
} = svs.core.log;
const logger = getLogger('tipsen:best-bet-rows');
const BestBetRows = () => {
  const getBestBetRows = useBestBetRows();
  const {
    bestBetRows,
    drawResultOutcomes,
    winDivisions,
    productId
  } = getBestBetRows();
  const eventTypeId = productId ? getDefinitionByProduct(productId).outcomes.eventTypeId : 0;
  const bestBetRowComponents = useMemo(() => {
    if (!(bestBetRows !== null && bestBetRows !== void 0 && bestBetRows.length)) {
      return null;
    }
    const rows = [];
    const numMatches = drawResultOutcomes.length;
    for (const [groupNumber, group] of bestBetRows.entries()) {
      if (!group.length) {
        continue;
      }
      rows.push(...group.map((row, index) => {
        const totalCorrects = drawResultOutcomes.length ? numMatches - groupNumber : '-';
        const winDiv = groupNumber + 1;
        return React.createElement(SingleRow, {
          className: winDivisions.includes(winDiv) ? 'pg_best_bet_rows__row--is-in-pricegroup' : '',
          drawResultOutcomes: drawResultOutcomes,
          eventTypeId: eventTypeId
          ,
          key: row.join('') + index,
          row: row,
          topic: totalCorrects
        });
      }));
    }
    logger.debug('Rerendered BestBetRows components');
    return rows;
  }, [bestBetRows, winDivisions, drawResultOutcomes, eventTypeId]);
  return React.createElement(SingleRowsPresentation, null, bestBetRowComponents);
};
setGlobal('svs.tipsen.components.bestBetRows.BestBetRows', BestBetRows);

 })(window);