(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/best-bet-rows/assets/javascripts/best-bet-row.js') >= 0) return;  svs.modules.push('/components/tipsen/best-bet-rows/assets/javascripts/best-bet-row.js');
"use strict";

function _classPrivateFieldInitSpec(e, t, a) { _checkPrivateRedeclaration(e, t), t.set(e, a); }
function _checkPrivateRedeclaration(e, t) { if (t.has(e)) throw new TypeError("Cannot initialize the same private elements twice on an object"); }
function _classPrivateFieldGet(s, a) { return s.get(_assertClassBrand(s, a)); }
function _classPrivateFieldSet(s, a, r) { return s.set(_assertClassBrand(s, a), r), r; }
function _assertClassBrand(e, t, n) { if ("function" == typeof e ? e === t : e.has(t)) return arguments.length < 3 ? t : n; throw new TypeError("Private element is not present on this object"); }
const {
  getLogger
} = svs.core.log;
const {
  getDefinitionByProduct
} = svs.components.tipsen.engine.utils;
const {
  helpers
} = svs.components.tipsen.store;
const {
  utils
} = svs.components.tipsen.expandedSystems;
const logger = getLogger('tipsen:best-bet-rows');
var _srow = new WeakMap();
var _maxCapRowCount = new WeakMap();
var _totalRowCount = new WeakMap();
var _calculateBestBetRows = new WeakMap();
class BestBetRow {
  constructor(productBet) {
    var _productBet$gameBoard, _productBet$gameBoard2;
    let {
      maxCapRowCount = 60
    } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    _classPrivateFieldInitSpec(this, _srow, []);
    _classPrivateFieldInitSpec(this, _maxCapRowCount, 0);
    _classPrivateFieldInitSpec(this, _totalRowCount, 0);
    _classPrivateFieldInitSpec(this, _calculateBestBetRows, void 0);
    const {
      boardData,
      systemType,
      systemNum
    } = (_productBet$gameBoard = productBet === null || productBet === void 0 || (_productBet$gameBoard2 = productBet.gameBoard) === null || _productBet$gameBoard2 === void 0 ? void 0 : _productBet$gameBoard2.boards) !== null && _productBet$gameBoard !== void 0 ? _productBet$gameBoard : {};
    const {
      productId
    } = productBet;
    const eventTypeId = productId ? getDefinitionByProduct(productId).outcomes.eventTypeId : 1;
    _classPrivateFieldSet(_maxCapRowCount, this, maxCapRowCount);
    const then = new Date();
    _classPrivateFieldSet(_srow, this, helpers.getExpandedRows(boardData, systemType, systemNum, {
      eventTypeId
    }));
    _classPrivateFieldSet(_totalRowCount, this, _classPrivateFieldGet(_srow, this).length);
    logger.info("Expanded srows for ".concat(systemType, " ").concat(systemNum, " ").concat(this.totalRowCount, " in ").concat(Date.now() - then, "ms"));
    _classPrivateFieldSet(_calculateBestBetRows, this, utils.calculateBestBetRows(_classPrivateFieldGet(_srow, this), undefined, undefined));
    this.calculate = this.calculate.bind(this);
  }
  get srow() {
    return _classPrivateFieldGet(_srow, this);
  }
  get totalRowCount() {
    return _classPrivateFieldGet(_totalRowCount, this);
  }
  calculate(drawResultOutcomes, winDivisions) {
    const then = new Date();
    const cacheKey = drawResultOutcomes.join('');
    const correctRowGroups = _classPrivateFieldGet(_calculateBestBetRows, this).call(this, drawResultOutcomes);
    const lowestWinDivision = winDivisions.slice().pop();
    const bestBetRows = [];
    const winDivisionsRowCount = [];
    let currentCount = 0;
    for (const [index, group] of correctRowGroups.entries()) {
      const winDivision = index + 1;
      const numberOfRowsInGroup = group.length;
      let slice;
      winDivisionsRowCount.push(numberOfRowsInGroup);

      if (lowestWinDivision && lowestWinDivision >= winDivision) {
        bestBetRows.push(group.slice(0, slice));
        currentCount += numberOfRowsInGroup;
        continue;
      } else if (numberOfRowsInGroup + currentCount > _classPrivateFieldGet(_maxCapRowCount, this)) {
        slice = Math.max(_classPrivateFieldGet(_maxCapRowCount, this) - currentCount, 0);
      }
      bestBetRows.push(group.slice(0, slice));
      currentCount += numberOfRowsInGroup;
    }
    logger.debug('winDivisionsRowCount', winDivisionsRowCount);
    logger.debug('calculated best bet rows', bestBetRows);
    logger.info("Calculated (".concat(this.totalRowCount, ") best bet rows for cache ").concat(cacheKey, " in ").concat(Date.now() - then, "ms"));
    return Object.freeze({
      bestBetRows: Object.freeze(bestBetRows),
      winDivisionsRowCount: Object.freeze(winDivisionsRowCount)
    });
  }
}
setGlobal('svs.tipsen.components.bestBetRows.BestBetRow', BestBetRow);

 })(window);